import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";

import router from "./router";

import "./assets/style/_themes.css";
import "@/views/styleSheet.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import locale from "element-plus/es/locale/lang/zh-cn";
// 阿里巴巴矢量图标
import "./assets/icon-font/iconfont.css";
import "./markdownstyle/index.css";

const app = createApp(App);

app.use(ElementPlus, {
  locale,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 将顶层的await调用移到一个异步函数中
async function initApp() {
  const { getUserPermissionInfo } = await import("@/router");
  await getUserPermissionInfo();

  app.use(store);
  app.use(router);
  app.mount("#app");
}

initApp().catch((error) => {
  console.error("Failed to initialize the app:", error);
});

// app.vue写在script里面 main.js写在app挂载完之后
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};
